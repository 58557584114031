var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container max-w-screen-lg item-center mx-auto",attrs:{"id":"event-ticket"}},[_c('div',{staticClass:"pt-8 pb-16 px-8 md:px-16 min-h-screen"},[_vm._m(0),_c('div',{staticClass:"flex flex-wrap mt-5"},[_c('ul',{staticClass:"flex list-none flex-grow flex-row gap-2"},[_c('li',{staticClass:"text-center flex-auto md:flex-none"},[_c('a',{staticClass:"text-base font-bold px-12 py-1 rounded-t-xl cursor-pointer",class:{
                'text-black bg-grey-3': _vm.openTab !== 1,
                'text-white bg-primary': _vm.openTab === 1,
              },on:{"click":function($event){return _vm.toggleTabs(1)}}},[_vm._v(" Current ")])]),_c('li',{staticClass:"text-center flex-auto md:flex-none"},[_c('a',{staticClass:"text-base font-bold px-12 py-1 rounded-t-xl cursor-pointer",class:{
                'text-black bg-grey-3': _vm.openTab !== 2,
                'text-white bg-primary': _vm.openTab === 2,
              },on:{"click":function($event){return _vm.toggleTabs(2)}}},[_vm._v(" Past ")])])]),_c('div',{staticClass:"flex mt-1 w-full border-t-2 border-grey-3 z-0"},[_c('div',{staticClass:"py-5 flex-auto"},[_c('div',{staticClass:"tab-content tab-space"},[(_vm.openTab === 1)?_c('CurrentTicket'):_vm._e(),(_vm.openTab === 2)?_c('PastTicket'):_vm._e()],1)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-end"},[_c('img',{attrs:{"src":require("@/assets/voucher-logo.svg"),"alt":""}}),_c('h3',{staticClass:"font-semibold text-3xl ml-3"},[_vm._v("My Ticket")])])
}]

export { render, staticRenderFns }