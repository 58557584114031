<template>
  <div>
    <div
      class="container max-w-screen-lg item-center mx-auto"
      id="event-ticket"
    >
      <div class="pt-8 pb-16 px-8 md:px-16 min-h-screen">
        <div class="flex items-end">
          <img src="@/assets/voucher-logo.svg" alt="" />
          <h3 class="font-semibold text-3xl ml-3">My Ticket</h3>
        </div>
        <div class="flex flex-wrap mt-5">
          <ul class="flex list-none flex-grow flex-row gap-2">
            <li class="text-center flex-auto md:flex-none">
              <a
                class="text-base font-bold px-12 py-1 rounded-t-xl cursor-pointer"
                @click="toggleTabs(1)"
                :class="{
                  'text-black bg-grey-3': openTab !== 1,
                  'text-white bg-primary': openTab === 1,
                }"
              >
                Current
              </a>
            </li>
            <li class="text-center flex-auto md:flex-none">
              <a
                class="text-base font-bold px-12 py-1 rounded-t-xl cursor-pointer"
                @click="toggleTabs(2)"
                :class="{
                  'text-black bg-grey-3': openTab !== 2,
                  'text-white bg-primary': openTab === 2,
                }"
              >
                Past
              </a>
            </li>
          </ul>
          <div class="flex mt-1 w-full border-t-2 border-grey-3 z-0">
            <div class="py-5 flex-auto">
              <div class="tab-content tab-space">
                <CurrentTicket v-if="openTab === 1" />
                <PastTicket v-if="openTab === 2" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EventTicket",
  components: {
    CurrentTicket: () => import("@/components/CurrentTicket.vue"),
    PastTicket: () => import("@/components/PastTicket.vue"),
  },
  data() {
    return {
      openTab: 1,
    };
  },
  methods: {
    toggleTabs: function (tabNumber) {
      this.openTab = tabNumber;
    },
  },
};
</script>
